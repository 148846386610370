import gameData from "./game-data.json";

function getGameBlockHtml(game) {
  return `<div class="game-thumb" title=${game.GameName} onclick="openGame('${btoa(game.EmbedURL)}')">
            ${game.ImageTag.replace("<img ","<img title='"+game.GameName+"' ")}
         </div>`;
}
let searchGame = "";
function initGameList() {
  let categories = ["HeaderGames", "FeaturedGames", "NewGames","ioGames", "SportsGames","CasualGames", "PuzzleGames", "ActionGames", "DrivingGames", "ShootingGames"];
  for (let index = 0; index < categories.length; index++) {
    const category = categories[index];
    renderGamesRow("#" + category, category);
  }
}
function renderGamesRow(rowSelector, categoryName) {
  let gameRow = $(rowSelector);
  let games = gameData.find((x) => x.categoryName == categoryName).games;
  if (searchGame) {
    games = games = games.filter((x) => x.GameName.toLowerCase().includes(searchGame.toLowerCase()));
  }
  if ($(rowSelector).children().length) {
    $(rowSelector).slick("unslick");
    $(rowSelector).empty();
  }
  for (let index = 0; index < games.length; index++) {
    const game = games[index];
    gameRow.append(getGameBlockHtml(game));
  }
  if (games.length == 0) {
    $(rowSelector).parent().hide();
  } else {
    $(rowSelector).parent().show();
  }
  $(rowSelector).slick({
    slidesToShow: 5,
  });
}
window.openGame = function (frame) {
  console.log(atob(frame));
  $("#gameModal .modal-content").html('<span class="close" onclick="closeGame()">&times;</span>'+atob(frame));
  $("#gameModal").show();
};
window.closeGame = function () {
  $("#gameModal .modal-content").html("");
  $("#gameModal").hide();
};
window.goToSection = (category) => {
  $("#searchGame").val("");
  onSearch();
  setTimeout(() => {
    $("html, body").animate({
      scrollTop: $("#" + category).position().top - 50,
    });
  });
};
window.onload = function () {
  const urlParams = new URLSearchParams(window.location.search);
  const searchParam = urlParams.get("q");
  console.log(searchParam);
  if (searchParam) {
    searchGame = searchParam;
    $("#searchGame").val(searchParam);
  }
  initGameList();

  $("#searchGame").keyup(function (event) {
    if (event.keyCode === 13) {
      onSearch();
    } else if (!$("#searchGame").val()) {
      onSearch();
    }
  });
  $("#searchGame").on("search", function (e) {
    if (!$("#searchGame").val()) {
      onSearch();
    }
  });
};
function onSearch() {
  let searchTerm = $("#searchGame").val();
  searchGame = searchTerm;
  console.log("search", searchGame);
  initGameList();
}
window.onSearch = onSearch;
